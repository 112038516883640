<template>
  <div id="main-logo" style="height: 100%">
    <v-app-bar
      class="d-flex justify-center align-center text-center home-app-bar"
      flat
    >
      <div class="home-spacer" style="width: 100px"></div>
      <div class="d-flex justify-center align-center text-center">
        <img class="mr-6 roku-logo" src="@/assets/Roku Logo.svg" />
        <img class="walmart-logo" src="@/assets/Walmart Logo.svg" />
      </div>
      <SwitchLangButton />
    </v-app-bar>
    <v-main class="main-layout">
      <slot class="main-layout"></slot>
    </v-main>
  </div>
</template>

<script>
import SwitchLangButton from '@/components/SwitchLangButton.vue'

export default {
  name: 'MainLayout',
  data() {
    return {}
  },
  created() {
    window.scrollTo(0, 0)
  },
  components: { SwitchLangButton },
}
</script>

<style>
.home-app-bar .v-toolbar__content,
.home-app-bar .v-toolbar__extension {
  max-height: 50px;
  justify-content: space-between !important;
  column-gap: 24px;
  align-items: center !important;
}
/* #main-logo .roku-logo {
  height: 48px;
}
#main-logo .walmart-logo {
  height: 75px;
} */
/* @media only screen and (max-width: 1680px) and (min-width: 1520px) { */
#main-logo .roku-logo {
  height: 32px !important;
}
#main-logo .walmart-logo {
  height: 55px !important;
}
/* } */

@media (max-width: 756px) {
  .home-app-bar .v-toolbar__content,
  .home-app-bar .v-toolbar__extension {
    column-gap: 12px;
  }
  #main-logo .roku-logo {
    height: 20px !important;
  }
  #main-logo .roku-logo.mr-6 {
    margin-right: 12px !important;
  }
  #main-logo .walmart-logo {
    height: 30px !important;
  }
}
</style>
