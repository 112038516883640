import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import storageHelper from 'storage-helper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import('@/views/Protected.vue'),
    beforeEnter: async (to, from, next) => {
      if (process.env.VUE_APP_ISPW !== 'T') {
        next('/')
      } else {
        next()
      }
    },
  },
  {
    path: '/steps-to-stream',
    name: 'StepsToStream',
    component: () => import('@/views/StepsToStream.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/channels',
    name: 'Channels',
    component: () => import('@/views/Channels.vue'),
    props: { window: false },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/compare',
    name: 'Compare',
    component: () => import('@/views/Compare.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && process.env.VUE_APP_ISPW == 'T') {
    if (!storageHelper.getItem('user-password')) {
      next('/protected')
    } else if (
      storageHelper.getItem('user-password') !== process.env.VUE_APP_PW
    ) {
      next('/protected')
    } else next()
  } else next()
})

export default router
