<template>
  <svg
    width="57"
    height="100"
    viewBox="0 0 57 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.40533 98.48L54.767 53.115C55.1795 52.7033 55.5066 52.2141 55.7296 51.6756C55.9526 51.1371 56.067 50.5598 56.0663 49.977C56.0679 49.3932 55.9538 48.8148 55.7308 48.2753C55.5078 47.7358 55.1802 47.2457 54.767 46.8334L9.40533 1.47178C8.99318 1.05961 8.50387 0.732646 7.96535 0.509576C7.42683 0.286505 6.84964 0.171692 6.26675 0.171692C5.68386 0.171692 5.10667 0.286505 4.56815 0.509576C4.02963 0.732646 3.54033 1.05961 3.12817 1.47178L1.6102 2.99086C1.19802 3.40302 0.871057 3.89233 0.647987 4.43085C0.424916 4.96937 0.310104 5.54656 0.310104 6.12945C0.310104 6.71234 0.424916 7.28953 0.647987 7.82805C0.871057 8.36657 1.19802 8.85588 1.6102 9.26803L42.3191 49.977L1.60576 90.6859C1.19358 91.0981 0.866617 91.5874 0.643547 92.1259C0.420476 92.6644 0.305664 93.2416 0.305664 93.8245C0.305664 94.4074 0.420476 94.9846 0.643547 95.5231C0.866617 96.0616 1.19358 96.5509 1.60576 96.9631L3.12373 98.4811C3.53548 98.894 4.02478 99.2214 4.5635 99.4446C5.10222 99.6678 5.67974 99.7823 6.26287 99.7815C6.84665 99.7828 7.4249 99.6684 7.96426 99.445C8.50361 99.2216 8.99339 98.8936 9.40533 98.48Z"
      fill="#2DAEFF"
    />
  </svg>
</template>

<script>
export default {
  name: 'RightChevronBlue',
}
</script>
