import VueMq from 'vue-mq'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { VueHammer } from 'vue2-hammer'
import VueI18n from 'vue-i18n'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  messages: Object.freeze({ en, es }),
})

// https://kazupon.github.io/vue-i18n/guide/hot-reload.html#basic-example
if (module.hot) {
  module.hot.accept(
    ['./locales/en/translation.json', './locales/es/translation.json'],
    () => {
      i18n.setLocaleMessage(
        'en',
        Object.freeze(require('./locales/en/translation.json').default)
      )
      i18n.setLocaleMessage(
        'es',
        Object.freeze(require('./locales/es/translation.json').default)
      )
    }
  )
}

Vue.use(VueHammer)

Vue.config.productionTip = false
export const eventBus = new Vue()

Vue.use(VueMq, {
  breakpoints: {
    mobile: 756,
    tablet: 1263,
    tabletlg: 1519,
    laptop: 1680,
    desktop: Infinity,
  },
})

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
