<template>
  <svg
    width="83"
    height="83"
    viewBox="0 0 83 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M41.5637 76.0905C60.8937 76.0905 76.5637 60.4204 76.5637 41.0905C76.5637 21.7605 60.8937 6.09045 41.5637 6.09045C22.2338 6.09045 6.56372 21.7605 6.56372 41.0905C6.56372 60.4204 22.2338 76.0905 41.5637 76.0905Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <path
      d="M30.4695 48.6L37.9794 41.0901L30.4695 33.5801C30.2117 33.3506 30.0036 33.0708 29.8578 32.7579C29.7121 32.445 29.6317 32.1057 29.6218 31.7607C29.6118 31.4157 29.6724 31.0723 29.7999 30.7515C29.9273 30.4308 30.119 30.1394 30.363 29.8954C30.6071 29.6513 30.8984 29.4597 31.2192 29.3322C31.5399 29.2047 31.8834 29.1441 32.2284 29.1541C32.5734 29.1641 32.9127 29.2444 33.2256 29.3902C33.5384 29.5359 33.8182 29.7441 34.0478 30.0018L41.5577 37.5118L49.0737 29.9958C49.3032 29.7381 49.583 29.5299 49.8959 29.3842C50.2088 29.2384 50.5481 29.1581 50.8931 29.1481C51.2381 29.1382 51.5815 29.1988 51.9023 29.3262C52.223 29.4537 52.5144 29.6453 52.7584 29.8894C53.0025 30.1335 53.1941 30.4248 53.3216 30.7456C53.4491 31.0663 53.5097 31.4097 53.4997 31.7547C53.4897 32.0997 53.4094 32.4391 53.2636 32.7519C53.1179 33.0648 52.9097 33.3446 52.652 33.5741L45.142 41.0841L52.658 48.6C52.9157 48.8296 53.1239 49.1094 53.2696 49.4223C53.4154 49.7351 53.4957 50.0745 53.5057 50.4195C53.5156 50.7645 53.455 51.1079 53.3276 51.4286C53.2001 51.7494 53.0085 52.0407 52.7644 52.2848C52.5203 52.5288 52.229 52.7205 51.9082 52.8479C51.5875 52.9754 51.2441 53.036 50.8991 53.0261C50.5541 53.0161 50.2147 52.9358 49.9019 52.79C49.589 52.6442 49.3092 52.4361 49.0797 52.1784L41.5697 44.6684L34.0538 52.1843C33.8242 52.4421 33.5444 52.6502 33.2315 52.796C32.9187 52.9417 32.5793 53.0221 32.2343 53.032C31.8893 53.042 31.5459 52.9814 31.2252 52.8539C30.9044 52.7265 30.6131 52.5348 30.369 52.2908C30.125 52.0467 29.9333 51.7554 29.8059 51.4346C29.6784 51.1139 29.6178 50.7704 29.6277 50.4254C29.6377 50.0804 29.718 49.7411 29.8638 49.4282C30.0096 49.1154 30.2177 48.8356 30.4754 48.606L30.4695 48.6Z"
      fill="#313131"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.608834"
        y="0.135568"
        width="81.9098"
        height="81.9098"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.97744" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.278 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="41.5637"
        y1="6.09045"
        x2="41.5637"
        y2="76.0905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.426" stop-color="white" />
        <stop offset="1" stop-color="#EEEEEE" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CloseBtn',
}
</script>
