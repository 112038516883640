<template>
  <svg
    width="55"
    height="98"
    viewBox="0 0 55 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0245 1.28056L1.28166 45.9123C0.874764 46.3174 0.552087 46.7987 0.332146 47.3285C0.112206 47.8583 -0.000667053 48.4262 8.09844e-06 48.9996C-0.0015102 49.574 0.110955 50.143 0.330925 50.6738C0.550892 51.2046 0.874012 51.6867 1.28166 52.0924L46.0245 96.7209C46.431 97.1264 46.9136 97.4481 47.4448 97.6676C47.976 97.887 48.5453 98 49.1202 98C49.6952 98 50.2645 97.887 50.7957 97.6676C51.3268 97.4481 51.8095 97.1264 52.216 96.7209L53.7133 95.2264C54.1198 94.8209 54.4423 94.3395 54.6623 93.8097C54.8824 93.2799 54.9956 92.712 54.9956 92.1385C54.9956 91.5651 54.8824 90.9972 54.6623 90.4674C54.4423 89.9376 54.1198 89.4562 53.7133 89.0507L13.5597 48.9996L53.7177 8.94863C54.1242 8.54314 54.4467 8.06174 54.6667 7.53193C54.8868 7.00211 55 6.43425 55 5.86078C55 5.2873 54.8868 4.71944 54.6667 4.18963C54.4467 3.65981 54.1242 3.17841 53.7177 2.77292L52.2204 1.27947C51.8143 0.873245 51.3316 0.551078 50.8003 0.331504C50.2689 0.11193 49.6992 -0.000725256 49.1241 7.1157e-06C48.5483 -0.00119075 47.9779 0.111343 47.4459 0.33113C46.9139 0.550918 46.4308 0.873595 46.0245 1.28056Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'LeftChevron',
}
</script>
