<template>
  <v-btn
    @click="$root.$i18n.locale = $root.$i18n.locale === 'en' ? 'es' : 'en'"
    class="c-btn"
    color="blue"
    dark
    medium
    >{{
      $root.$i18n.locale === 'en' ? 'Español' : 'English',
    }}</v-btn
  >
</template>

<script>
export default {
  name: 'SwitchLangButton',
}
</script>

<style scoped>
.c-btn {
  width: 60px !important;
  height: 24px !important;
  font-size: 12px !important;
}

@media (min-width: 756px) {
  .c-btn {
    width: 100px !important;
    height: 32px !important;
    font-size: 16px !important;
  }
}
</style>
