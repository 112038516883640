import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import RightChevron from '@/icons/RightChevron'
import LeftChevron from '@/icons/LeftChevron'
import RightChevronBlue from '@/icons/RightChevronBlue'
import LeftChevronBlue from '@/icons/LeftChevronBlue'
import CloseBtn from '@/icons/CloseBtn'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        purple: '#350a44',
      },
    },
  },
  icons: {
    values: {
      'right-chevron': {
        component: RightChevron,
      },
      'left-chevron': {
        component: LeftChevron,
      },
      'right-chevron-blue': {
        component: RightChevronBlue,
      },
      'left-chevron-blue': {
        component: LeftChevronBlue,
      },
      'close-btn': {
        component: CloseBtn,
      },
    },
  },
})
