<template>
  <v-container>
    <div class="layout-flex">
      <div
        class="layout-flex white--text"
        :style="$mq === 'mobile' ? 'height: 100%' : 'height: 100%'"
      >
        <h1 class="h1">{{ $t('main.title') }}</h1>
        <h2 class="h2">{{ $t('main.subtitle') }}</h2>
        <div class="flex-row-c home-btns">
          <div class="flex-3 home-btn" @click="go('/steps-to-stream', 0)">
            <img style="cursor: pointer" src="@/assets/homepage/blank.png" />
            <div class="home-btn-container font-bold">
              <span>{{ $t('main.button1') }}</span>
            </div>
          </div>
          <div class="flex-3 home-btn" @click="go('/channels', 0)">
            <img style="cursor: pointer" src="@/assets/homepage/blank.png" />
            <div class="home-btn-container font-bold">
              <span>{{ $t('main.button2') }}</span>
            </div>
          </div>
          <div class="flex-3 home-btn" @click="go('/compare', 0)">
            <img style="cursor: pointer" src="@/assets/homepage/blank.png" />
            <div class="home-btn-container font-bold">
              <span>{{ $t('main.button3') }}</span>
            </div>
          </div>
        </div>
        <div class="flex-row-c home-vids mb-2 mt-6">
          <!-- <div class="flex-2">
            <img
              @click="openOne()"
              style="cursor: pointer"
              src="@/assets/homepage/Video 1.png"
            />
          </div> -->

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              @click="openTwo()"
              class="flex-2"
              style="cursor: pointer; position: relative"
            >
              <img
                style="max-width: 300px"
                :src="
                  require($root.$i18n.locale === 'en'
                    ? '@/assets/homepage/Roku Device for Everyone.png'
                    : '@/assets/homepage/Spanish Find the right Roku Device.png')
                "
              />
              <img
                src="@/assets/homepage/play-icon.svg"
                style="
                  width: 2rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
              />
            </div>
            <h2 class="h2" style="margin: 0">
              {{ $t('main.find') }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="h5">
      {{ $t('main.site') }}
    </div>

    <!-- <v-dialog v-model="vidOne" :key="1">
      <div class="vids" style="position: relative" v-if="vidOne && video1">
        <v-btn class="close-btn" @click="vidOne = false" icon>
          <v-icon x-large>$close-btn</v-icon>
        </v-btn>

        <div
          v-if="$mq === 'mobile' || $mq === 'tablet'"
          v-html="video1muted"
        ></div>
        <div v-else v-html="video1"></div>
      </div>
    </v-dialog> -->

    <v-dialog v-model="vidTwo" :key="2">
      <div class="vids" style="position: relative" v-if="vidTwo">
        <v-btn class="close-btn" @click="vidTwo = false" icon>
          <v-icon x-large>$close-btn</v-icon>
        </v-btn>

        <div
          v-if="$mq === 'mobile' || $mq === 'tablet'"
          v-html="$root.$i18n.locale === 'en' ? video2muted : video2mutedSp"
        ></div>
        <div
          v-else
          v-html="$root.$i18n.locale === 'en' ? video2 : video2Sp"
        ></div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { eventBus } from '@/main.js'
import MainLayout from '@/layouts/MainLayout.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      // vidOne: false,
      vidTwo: false,
      // video2: `
      // <video controls autoplay class="video">
      //   <source src="/vid/all-in-one.mp4">
      // </video>
      // `,
      // video2muted: `
      // <video controls autoplay muted class="video">
      //   <source src="/vid/all-in-one.mp4">
      // </video>
      // `,

      // video1: `<iframe
      //     id="video1"
      //     class="video-player"
      //     src="https://player.vimeo.com/video/565795516?autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      //     frameborder="0"
      //     allow="autoplay; fullscreen; picture-in-picture"
      //     title="How Streaming Works"
      //   ></iframe>`,
      video2: `<iframe id="video2"
          class="video-player" src="https://player.vimeo.com/video/825286504?h=638e35a37e&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="22-19_All-In-One-Player-Video_JA_60_082622_showcase.mp4"></iframe>`,
      video2Sp: `<iframe id="video2"
          class="video-player" src="https://player.vimeo.com/video/825287574?h=734364867b&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="22-19_All-In-One-Player-Video_JA_60_082622_showcase.mp4"></iframe>`,
      // video1muted: `<iframe
      //     id="video1"
      //     class="video-player"
      //     src="https://player.vimeo.com/video/565795516?autoplay=1&muted=1&responsive=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      //     frameborder="0"
      //     allow="autoplay; fullscreen; picture-in-picture"
      //     title="How Streaming Works"
      //     webkitallowfullscreen mozallowfullscreen allowfullscreen
      //   ></iframe>`,
      video2muted: `<iframe id="video2"
          class="video-player" src="https://player.vimeo.com/video/825286504?h=638e35a37e&amp;badge=0&amp;autopause=0&amp;muted=1&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="22-19_All-In-One-Player-Video_JA_60_082622_showcase.mp4"></iframe>`,
      video2mutedSp: `<iframe id="video2"
          class="video-player" src="https://player.vimeo.com/video/825287574?h=734364867b&amp;badge=0&amp;autopause=0&amp;muted=1&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="22-19_All-In-One-Player-Video_JA_60_082622_showcase.mp4"></iframe>`,
    }
  },
  created() {
    this.$emit(`update:layout`, MainLayout)
    window.scrollTo(0, 0)
  },
  methods: {
    go(route, val) {
      eventBus.$emit('setWindow', val)
      this.$router.push(route)
    },
    openOne() {
      this.vidOne = true
    },
    openTwo() {
      this.vidTwo = true
    },
  },
}
</script>
<style>
.vids .close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.video-player {
  height: 640px;
  width: 100%;
}
.layout-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.home-btns {
  margin: 0 auto;
  width: 100%;
  align-items: center;
}
.home-btn {
  position: relative;
}
.home-btn-container {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2.5rem 3rem; /* image has some margins */
  text-align: center;
  text-rendering: optimizeLegibility;
  color: #46135f;
  font-size: 1.5rem;
  line-height: 1.2;
}
.home-vids {
  margin: 65px auto 40px auto;
  max-width: 100%;
  gap: 22px;
}
.home-vids img {
  max-height: 300px;
  object-fit: contain;
  width: auto;
}
@media (max-width: 756px) {
  .home-vids .flex-2 {
    margin: 0 12px;
  }
  .video-player {
    width: 100% !important;
  }
  .home-btns {
    margin: 0 auto;
  }
  .home-btn {
    max-width: 300px;
  }
  .home-vids {
    margin: 5px auto;
  }
  .home-vids img {
    margin: 0;
    max-height: 175px;
  }
  /* .close-btn {
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    margin: 0 !important;
  } */
}

@media (min-width: 757px) and (max-width: 899px) {
  .home-btn-container {
    font-size: 1.25rem;
  }
}

/* @media (min-width: 1520px) {
  .home-btn-container {
    font-size: 2rem;
  }
} */
</style>
