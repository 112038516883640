<template>
  <v-app>
    <component :is="layout">
      <router-view :selectedWindow="selectedWindow" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import '@/app.css'
import { eventBus } from '@/main.js'

export default {
  name: 'App',
  components: {},

  data: () => ({
    layout: `div`,
    selectedWindow: null,
  }),
  created() {
    window.scrollTo(0, 0)
    eventBus.$on('setWindow', (val) => {
      this.selectedWindow = val
    })
  },
}
</script>
