<template>
  <svg
    width="55"
    height="98"
    viewBox="0 0 55 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97553 96.7194L53.7183 52.0877C54.1252 51.6826 54.4479 51.2013 54.6679 50.6715C54.8878 50.1417 55.0007 49.5738 55 49.0003C55.0015 48.426 54.889 47.857 54.6691 47.3262C54.4491 46.7954 54.126 46.3132 53.7183 45.9076L8.97553 1.27908C8.569 0.873563 8.08637 0.551888 7.55519 0.332423C7.02402 0.112958 6.45471 0 5.87977 0C5.30483 0 4.73552 0.112958 4.20434 0.332423C3.67317 0.551888 3.19053 0.873563 2.784 1.27908L1.28674 2.77361C0.880181 3.17911 0.55768 3.66051 0.337653 4.19032C0.117626 4.72014 0.00437974 5.288 0.00437974 5.86147C0.00437974 6.43494 0.117626 7.0028 0.337653 7.53262C0.55768 8.06243 0.880181 8.54383 1.28674 8.94933L41.4403 49.0003L1.28236 89.0514C0.875801 89.4569 0.553301 89.9383 0.333273 90.4681C0.113246 90.9979 0 91.5658 0 92.1392C0 92.7127 0.113246 93.2806 0.333273 93.8104C0.553301 94.3402 0.875801 94.8216 1.28236 95.2271L2.77962 96.7205C3.18576 97.1268 3.66838 97.4489 4.19975 97.6685C4.73113 97.8881 5.30077 98.0007 5.87593 98C6.45176 98.0012 7.02212 97.8887 7.55412 97.6689C8.08611 97.4491 8.56921 97.1264 8.97553 96.7194Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'RightChevron',
}
</script>
